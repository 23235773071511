<template>
  <notification-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t(`shared.notification.${event}.title`) }}</span>
    </template>
    <p v-html="$t(`shared.notification.${event}.statusMessage`, {teamName, userNickname})" class="main-msg" />
    <p class="sub-msg" v-if="messageFromCaptain">{{ messageFromCaptain }}</p>
    <template v-slot:buttons>
      <button v-if="event !== 'TEAM_JOIN_FAIL'" @click="route">
        {{ $t(`shared.notification.${event}.${realTime ? 'realTimeButton' : 'button'}`, { teamName, userNickname }) }}
      </button>
    </template>
  </notification-item>
</template>

<script>
import Specific from '@shared/types/Specific';
import NotificationItem from '@shared/components/notification/NotificationItem.vue';
import { historyMove } from '@shared/utils/routerUtils';

export default {
  name: 'TeamJoinItem',
  components: { NotificationItem },
  props: {
    message: Specific,
  },
  computed: {
    messageFromCaptain() {
      return this.message?.data?.message || '';
    },
    team() {
      return this.message?.data?.team || {};
    },
    user() {
      return this.message?.data?.user || {};
    },
    tournament() {
      return this.message?.data?.tournament || {};
    },
    teamName() {
      return this.team.name || this.$t('anonymous.team');
    },
    userNickname() {
      return this.user.nickname || this.$t('anonymous.user');
    },
    event() {
      return this.message.event;
    },
    realTime() {
      return Boolean(this.message?.realTime);
    },
  },
  methods: {
    route() {
      const { gameId } = this.team;
      const { teamId } = this.team;
      const path = this.event === 'TEAM_JOIN_REQUEST' ? 'applicant' : 'home';
      this.$emit('read-item', this.message || {});
      historyMove(`${process.env.VUE_APP_HOME_URL}/teams/${gameId}/${teamId}/${path}`);
    },
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
